* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-collapse: collapse;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --color-green: #97fa84;
    --color-green-dark: #7de26b;
    --color-green-light: #dbffd4;
    --color-green-hover: #7ff867;
    --color-green-card: #b3ffa5;
    --color-green-success: #009806;
    --color-red: #ffa0a0;
    --color-red-dark: #fc9191;
    --color-red-hover: #fc8787;
    --color-red-light: #ffbcbc;
    --color-red-error: #b30000;
    --color-blue: #9ee3ff;
    --color-blue-dark: #4ac9ff;
    --color-blue-light: #ddf6ff;
    --color-blue-hover: #81d9ff;
    --color-orange: #ffe499;
    --color-orange-dark: #ffdd81;
    --color-orange-light: #ffebb5;
    --color-orange-hover: #ffd86e;
    --box-shadow: 0 1px 5px #d2d2d2;
    --box-shadow-cards: 1px 1px 3px #c7c7c7;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

html {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

body {
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
    font-style: normal;
    width: 100%;
    height: 100%;
    color: #252525;
    overflow-wrap: break-word;
}

#root {
    min-height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}

header {
    height: 50px;
    width: 100%;
}

main {
    width: 100%;
    padding: 15px 10px 0;
    position: relative;
    flex: 1;
}

h1, h2, h3 {
    font-weight: 500;
}

h1 {
    font-size: 25px;
    margin-left: 10px;
    border-bottom: 1px solid #dfdfdf;
    display: inline-block;
}

button {
    background-color: transparent;
    font-size: inherit;
    cursor: pointer;
}

button:disabled {
    color: #555555;
}

textarea,
input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none;
}

.wrapper {
    width: 100%;
}

.buttons__wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media screen and (min-width: 1024px) {
    header, main {
        width: 1000px;
    }
}