.graphs__container {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.graph__single {
    width: 50%;
    min-width: 140px;
    max-width: 250px;
    position: relative;
}

.graph__content {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graph__title {
    font-size: 4.5vw;
    margin-bottom: 5px;
}

.graph__number {
    font-weight: 600;
    font-size: 20px;
}

.progressbar {
    width: 50%;
}

.loader {
    stroke-dasharray: 0 18 18;
    transition: stroke-dasharray 2s linear;
}

svg:hover .loader {
    stroke-dasharray: 18 0 18;
}

@media screen and (min-width: 600px) {
    .graph__title {
        font-size: 25px;
    }
}