.cards__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px auto 20px;
}

.cards__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 0;
}

@media screen and (min-width: 600px) {
    .cards__container {
        width: 580px;
    }
}