.menu-buttons__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;
    height: 120px;
    padding: 10px 15px;
    box-shadow: var(--box-shadow);
    margin-top: 20px;
    border-radius: 2px;
}

.menu-button--learn {
    background: var(--color-green-card);
}

.menu-button--study {
    background: var(--color-red);
}

.menu-button--hanzi {
    background: var(--color-blue);
}

.menu-button--config {
    background: var(--color-orange);
}

.menu-button__icon {
    font-size: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-button--hanzi .menu-button__icon,
.menu-button--config .menu-button__icon {
    font-size: 38px;
}

.menu-button__title {
    font-size: 20px;
    margin-top: 5px;
}

.menu-button__subtitle {
    font-size: 11px;
    font-style: italic;
    color: #252525;
    margin-top: -2px;
}

@media screen and (min-width: 380px) {
    .menu-button {
        width: 150px;
        height: 130px;
        margin-top: 30px;
        margin-right: 3%;
        margin-left: 3%;
    }
}

@media (hover: hover) {
    .menu-button--learn:hover {
        background: var(--color-green);
    }
    
    .menu-button--study:hover {
        background: var(--color-red-hover);
    }
    
    .menu-button--hanzi:hover {
        background: var(--color-blue-hover);
    }
    
    .menu-button--config:hover {
        background: var(--color-orange-hover);
    }
}