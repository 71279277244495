.modal__background {
    position: fixed;
    background-color: #a5a5a5d6;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 200ms;
    z-index: 99999;
}

.modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 3px solid #000000;
    border-radius: 5px;
    background-color: #ffffff;
    max-width: 380px;
    margin-top: 10px;
}

.modal__guest-session {
    padding: 25px 25px 20px;
}

.modal__cards,
.modal__create-user {
    justify-content: space-around;
    height: 70%;
    min-height: 440px;
    max-height: 500px;
    padding: 40px;
}

.modal__study {
    justify-content: space-around;
    height: 85%;
    min-height: 440px;
    max-height: 520px;
    padding: 20px 40px 40px;
}

.modal__revision {
    justify-content: space-between;
    height: 70%;
    min-height: 440px;
    max-height: 500px;
    padding: 20px 30px 25px;
}

.modal__edit-user,
.modal__reset-password {
    justify-content: space-between;
    min-height: 400px;
    padding: 40px 45px 40px;
}

.modal__reset {
    min-height: 400px;
    text-align: center;
    padding: 40px 45px 40px;
}

.modal__button-close {
    min-width: 110px;
    margin-top: 18px;
    background: var(--color-red-dark);
    padding: 12px 18px;
    border-radius: 3px;
    cursor: pointer;
}

.modal__expired-session {
    min-height: 350px;
    text-align: center;
    padding: 50px 45px 50px;
    justify-content: space-between;
}

.modal__expired-button {
    min-width: 110px;
    background: var(--color-green);
    padding: 12px 18px;
    border-radius: 3px;
    cursor: pointer;
}

.modal__study img {
    width: 80px;
    margin: 0 auto;
}

.modal__study h2{
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
}

.modal__study p {
    margin-bottom: 10px;
}

.modal__study-button {
    display: inline-block;
    padding: 15px 20px;
    min-width: 200px;
    border-radius: 3px;
    margin-top: 20px;
    box-shadow: var(--box-shadow);
    text-align: center;
}

.modal__study-button--ok {
    background-color: var(--color-blue);
}

.modal__study-button--again {
    background-color: var(--color-green);
}

.modal__study-button--back {
    background-color: var(--color-blue);
}

@media (hover: hover) {
    .modal__button-close:hover {
        background: var(--color-red-hover);
    }

    .modal__study-button--ok:hover {
        background-color: var(--color-blue-hover);
    }
    
    .modal__study-button--again:hover {
        background-color: var(--color-green-hover);
    }
    
    .modal__study-button--back:hover {
        background-color: var(--color-blue-hover);
    }
}

@media screen and (min-width: 480px) {
    .modal__study {
        margin-top: 0;
    }
}