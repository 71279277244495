.revision-details__title {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
}

.revision-details__hanzilist {
    font-size: 34px;
    font-weight: normal;
    text-align: center;
    line-height: 50px;
}

.revision-details__hanzilist span {
    margin-left: 10px;
    margin-right: 10px;
}

.modal__revision p {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
}

.revision-details__button-wrapper {
    width: 100%;
    display: block;
    text-align: center;
}

.revision-details__button {
    display: inline-block;
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--color-blue);
    box-shadow: var(--box-shadow);
}


@media (hover: hover) {
    .revision-details__button:hover {
        background-color: var(--color-blue-hover);

    }
}