.hanzi-details__id {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    border: 3px solid #000000;
    display: grid;
    place-items: center;
    font-size: 20px;
    font-weight: 600;
}

.hanzi-details__top {
    display: flex;
    justify-content: space-between;
}

.hanzi-details__primary h2{
    font-size: 25px;
}

.hanzi-details__primary p{
    font-size: 90px;
    text-align: center;
}

.hanzi-details__pronunciation {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.hanzi-details__pronunciation h2 {
    font-size: 15px;
}

.hanzi-details__pronunciation p {
    text-align: center;
    margin-top: 16px;
    font-size: 36px;
}

.hanzi-details__listen-btn {
    padding: 9px 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: var(--color-blue);
    box-shadow: var(--box-shadow);
    margin-top: 20px;
}

.hanzi-details__listen-btn svg {
    font-size: 18px;
}

.hanzi-details__listen-btn span {
    font-size: 14px;
    margin-left: 3px;
}

.hanzi-details__meaning {
    margin-top: 10px;
    min-height: 75px;
}

.hanzi-details__meaning p{
    margin-top: 10px;
}

.hanzi-details__progress {
    margin-top: 30px;
}

.hanzi-details__next-button-wrapper {
    width: 100%;
    display: block;
    text-align: center;
}

.hanzi-details__legend {
    position: relative;
    width: 100%;
    height: 10px;
    margin-top: 30px;
    background: linear-gradient(to right, #ff7147 0%, #f7b054 18%, #ffe380 30%, #c8ff95 50%, #88ea4e 100%);
    border-radius: 2px;
    position: relative;
    filter: drop-shadow(2px 2px 2px #bebebe);
    margin-left: auto;
    margin-right: auto;
}

.hanzi-details__legend-icon-wrapper {
    position: absolute;
    width: calc(100% - 5px);
    left: -12px;
    top: -25px;
}

.hanzi-details__legend-icon {
    position: absolute;
    font-size: 30px;
}

.hanzi-details__next-button {
    display: inline-block;
    margin-top: 60px;
    padding: 12px 25px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    background: var(--color-green);
    box-shadow: var(--box-shadow);
}

.hanzi-details__arrow {
    position: absolute;
    bottom: -60px;
    font-size: 35px;
    cursor: pointer;
    transition: transform 100ms linear;
}

.hanzi-details__arrow--left {
    transform: rotateZ(180deg);
    left: 10px;
}

.hanzi-details__arrow--right {
    right: 10px;
}

@media (hover: hover) {
    .hanzi-details__next-button:hover {
        background: var(--color-green-hover);
    }

    .hanzi-details__listen-btn:hover {
        background-color: var(--color-blue-hover);
    }

    .hanzi-details__arrow--left:hover {
        transform: rotateZ(180deg) scale(1.3, 1.3);
    }
    
    .hanzi-details__arrow--right:hover {
        transform: scale(1.3, 1.3);
    }
}