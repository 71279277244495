.hanzi-card {
    width: 45px;
    height: 55px;
    margin: 10px 5px;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #afafaf;
    box-shadow: var(--box-shadow-cards);
}

.hanzi-card--list {
    width: 50px;
    height: 60px;
}

.hanzi-card--learned {
    background: linear-gradient(to bottom, var(--color-green), #ffffff);
}

.hanzi-card--neutral {
    background: linear-gradient(to bottom, #e2e2e2, #f8f8f8);
}

.hanzi-card__primary {
    font-size: 18px;
}

.hanzi-card__secondary {
    font-size: 10px;
    margin-top: 0px;
}

.hanzi-card__id {
    position: absolute;
    top: 3px;
    right: 4px;
    font-size: 6px;
    color: #444444;
}

.revision-card {
    background: linear-gradient(to bottom, #ffe056, #ffeab0, #ffe056);
    border: 1px solid #c5a002;
}

.revision-card__title {
    text-align: center;
    font-size: 7px;
}

@media screen and (min-width: 480px) {
    .hanzi-card {
        width: 58px;
        height: 72px;
        margin: 10px 10px;
    }

    .hanzi-card__id {
        font-size: 8px;
    }

    .hanzi-card__primary {
        font-size: 20px;
    }

    .hanzi-card__secondary {
        margin-top: 2px;
    }

    .revision-card__title {
        font-size: 9px;
    }
}