.back__button {
    font-size: 22px;
    font-weight: 500;
    font-family: inherit;
    display: flex;
    align-items: center;
}

.back__icon {
    margin-right: 1px;
    margin-top: 1px;
}