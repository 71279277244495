.learn__level-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    position: relative;
}

.learn__level-title {
    text-align: center;
}

.learn__level-title--completed {
    color: #d6a800;
}

.learn__level-cards {
    display: flex;
}

.learn__level-button {
    max-width: 200px;
    min-height: 38px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    z-index: 10;
    padding: 10px 16px;
    font-size: 14px;
    border: 1px solid #000000;
    border-radius: 3px;
    box-shadow: var(--box-shadow-cards);
    cursor: pointer;
    position: relative;
}

.learn__level-button--current {
    background-color: var(--color-green);
}

@media (hover: hover) {
    .learn__level-button--current:hover {
        background-color: var(--color-green-hover);
    }
}

.learn__level-button--locked {
    background-color: #efefef;
}

.learn__level-button--locked::before {
    content: attr(data-tooltip);
    position: absolute;
    pointer-events: none;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    width: 185px;
    background-color: #545454;
    color: #ffffff;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    cursor: initial;
    opacity: 0;
    transition: .3s opacity;   
}

.learn__level-button--locked:hover::before {
    opacity: 1;
}

.learn__level-button--locked::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid #545454;
    border-color: #545454 transparent transparent transparent;
    opacity: 0;
    transition: .3s opacity;   
}

.learn__level-button--locked:hover::after {
    opacity: 1;
}

@media screen and (min-width: 480px) {
    .learn__level-title {
        text-align: center;
        font-size: 30px;
    }

    .learn__level-button {
        max-width: 220px;
        min-height: 40px;
        font-size: 16px;
    }
}