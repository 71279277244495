.login__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 25px;
    margin: 0 auto;
}

.login__title {
    margin-top: 20px;
    margin-left: 0;
}

.login__form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.login__label {
    margin: 15px 0 4px 1px;
    font-size: 18px;
}

.login__input {
    height: 40px;
    font-size: 16px;
    padding-left: 5px;
    border: 2px solid #eaeaea;
    border-radius: 4px;
    margin-bottom: 10px;
}

.login__input:focus {
    border: 2px solid var(--color-blue);
}

.login__button {
    width: 100%;
    font-size: 20px;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    height: 50px;
    box-shadow: 2px 2px 10px #40404021;
    background-color: var(--color-green);
}

.login__forget-password {
    margin-top: 20px;
    margin-right: 3px;
    text-align: right;
    font-size: 15px;
    color: #0005ad;
}

@media (hover: hover) {
    .login__button:hover {
        background-color: var(--color-green-hover);
    }
}

.login__form p {
    height: 20px;
}

.login__message--error {
    color: var(--color-red-error);
}

.login__message--success {
    color: var(--color-green-success);
}

@media screen and (min-width: 600px) {
    .login__container {
        width: 520px;
    }
}