.edit-user__form {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
    padding: 0 10px;
    width: 100%;
}

.edit-user__label {
    margin: 15px 0 5px 1px;
}

.edit-user__input {
    padding-left: 10px;
    font-size: 16px;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e2e2e2;
}

.edit-user__input:focus {
    border: 2px solid var(--color-blue);
}

.edit-user__button {
    display: inline-block;
    font-size: 16px;
    padding: 15px 20px;
    border-radius: 3px; 
    min-width: 200px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    text-align: center;
}

.edit-user__button--modify {
    background-color: var(--color-green);
}

.edit-user__button--cancel {
    background-color: var(--color-red);
}

.edit-user__message {
    color: var(--color-red-error);
    font-weight: 600;
    margin-top: 15px;
    min-height: 20px;
    margin-left: 1px;
}

.modal__edit-user-button {
    display: inline-block;
    font-size: 16px;
    min-width: 180px;
    padding: 15px 20px;
    border-radius: 3px; 
    margin-top: 30px;
    cursor: pointer;
    background-color: var(--color-green);
    box-shadow: var(--box-shadow);
}

.modal__edit-user h2 {
    text-align: center;
}

.modal__edit-user p {
    margin-top: 20px;
}

@media (hover: hover) {
    .edit-user__button--cancel:hover {
        background-color: var(--color-red-hover);
    }

    .edit-user__button--modify:hover,
    .modal__edit-user-button:hover {
        background-color: var(--color-green-hover);
    }
}

@media screen and (min-width: 600px) {
    .edit-user__form {
        width: 520px;
    }
}