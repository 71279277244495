.panel__container {
    margin: 0 auto;
}

.modal__guest-session h2 {
    text-align: center;
    font-size: 26px;
}

.modal__guest-session p {
    font-size: 15px;
    margin-top: 16px;
    line-height: 1.2;
}

.modal__guest-session span {
    font-weight: 600;
    color: #b90000;
}

.modal__guest-button {
    display: inline-block;
    min-width: 200px;
    padding: 14px 18px;
    margin: 25px auto 0;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
}

.modal__guest-button--create {
    background-color: var(--color-green);
}

.modal__guest-button--skip {
    background-color: #e7e7e7;
}

.modal__guest-already-register {
    font-size: 15px;
    text-align: center;
    margin-top: 25px;
}

.modal__guest-link {
    display: block;
    margin-top: 5px;
    font-weight: 600;
    color: #0052ce;
    font-size: 15px;
}

.modal__guest-link:visited {
    color: #0052ce;
}

@media screen and (min-width: 480px) {
    .panel__container {
        width: 470px;
    }
}

@media (hover: hover) {
    .modal__guest-button--create:hover {
        background-color: var(--color-green-hover);
    }

    .modal__guest-button--skip:hover {
        background-color: #dfdfdf;
    }
}