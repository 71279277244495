.counters__container {
    display: flex;
}

.counter__single {
    min-width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
}

.counter__icon {
    font-size: 18px;
    margin-right: 4px;
    min-width: 18px;
}

.counter__icon--correct {
    color: #20d000;
    margin-top: -2px;
}

.counter__icon--wrong {
    color: #ff8585;
}

.counter__icon--remain {
    color: #8bc7ff;
    margin-top: -2px;
}

.counter__number {
    font-size: 20px;
    color: #272727;
}