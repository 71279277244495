.create-user__form {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
    padding: 0 10px;
    width: 100%;
}

.create-user__box--success {
    background-color: var(--color-green-light);
}

.create-user__label {
    margin: 15px 0 5px 1px;
}

.create-user__input {
    padding-left: 10px;
    font-size: 16px;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e2e2e2;
}

.create-user__input:focus {
    border: 2px solid var(--color-blue);
}

.create-user__button {
    font-size: 16px;
    padding: 15px 20px;
    border-radius: 3px; 
    margin-top: 20px;
    cursor: pointer;
    background-color: var(--color-green);
    box-shadow: var(--box-shadow);
}

.create-user__message {
    color: #b30000;
    font-weight: 600;
    margin-top: 15px;
    min-height: 20px;
    margin-left: 1px;
}

.modal__create-user h2 {
    text-align: center;
}

.modal__create-user-button {
    display: inline-block;
    font-size: 16px;
    min-width: 180px;
    padding: 15px 20px;
    border-radius: 3px; 
    margin-top: 30px;
    cursor: pointer;
    background-color: var(--color-green);
    box-shadow: var(--box-shadow);
}

@media (hover: hover) {
    .create-user__button:hover,
    .modal__create-user-button:hover {
        background-color: var(--color-green-hover);
    }
}

@media screen and (min-width: 600px) {
    .create-user__form {
        width: 520px;
    }
}