.config__button {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 3px;
  margin: 15px auto 15px;
  min-width: 200px;
  box-shadow: var(--box-shadow);
  text-align: center;
}

.config__button-create-user {
  background-color: var(--color-blue);
}

.config__button-change-pass {
  background-color: var(--color-blue);
}

.config__button-reset {
  background-color: var(--color-red);
}

.config__options {
    padding: 0 15px;
    margin: 30px auto 0;
    max-width: 340px;
}

.config__option {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.config__option-checkbox {
    min-width: 100px;
}

.config__option-explanation {
    text-align: left;
    margin-left: 15px;
}

.config__option-explanation h3 {
    font-size: 18px;
    font-weight: 600;
}

.config__option-explanation p {
    font-size: 12px;
    font-style: italic;
    color: #636363;
}

.reset__title {
  margin-bottom: 15px;
}

.reset_question {
  margin-top: 15px;
}

.reset__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.reset__button {
  padding: 15px 20px;
  min-width: 200px;
  border-radius: 3px;
  margin-top: 20px;
  font-size: 15px;
}

.reset__button--confirm {
  background-color: var(--color-red);
  box-shadow: var(--box-shadow);
}

.reset__button--cancel {
  background-color: var(--color-green);
  box-shadow: var(--box-shadow);
}

@media (hover: hover) {
  .config__button-create-user:hover,
  .config__button-change-pass:hover {
    background-color: var(--color-blue-hover);
  }

  .config__button-reset:hover,
  .reset__button--confirm:hover {
    background-color: var(--color-red-hover);
  }

  .reset__button--cancel:hover {
    background-color: var(--color-green-hover);
  }
}