.study-card {
    transition: transform 1.5s;
    position: absolute;
    width: 280px;
    height: 350px;
    perspective: 1000px;
}

.study-card--second {
    transform: translate3d(-10px, -10px, 0);
}

.study-card--first {
    transform: translate3d(-20px, -20px, 0);
}

.study-card--correct {
    transform: translate3d(-460px, -20px, 0);
}

.study-card--wrong {
    transform: translate3d(420px, -20px, 0);
}

.study-card__flip-wrapper {
    position: relative;
    width: 100%;
    height: 100%;   
    transform-style: preserve-3d; 
    transition: transform 1.5s;
}

.study-card__flip-wrapper--correct {
    transform: rotateY(-180deg);
}

.study-card__flip-wrapper--wrong {
    transform: rotateY(180deg);
}

.study-card__front {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    backface-visibility: hidden;
    padding: 25px 25px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    transition: background-color 300ms;
    border: 1px solid #dedede;
}

.study-card__front--correct {
    background-color: var(--color-green-light);
}

.study-card__front--wrong {
    background-color: var(--color-red-light);
}

.study-card--0 .study-card__front,
.study-card--first .study-card__front,
.study-card--second .study-card__front {
    box-shadow: 2px 3px 4px #e2e2e2;
}

.study-card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-green);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Añadido para solucionar un bug en modo development */
    transform: rotateY(180deg);
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    border: 1px solid #dedede;
}

.study-card__back--wrong {
    background-color: var(--color-red);
} 

.study-card__showhide-icon {
    position: absolute;
    right: 18px;
    top: 14px;
    color: #a2a2a2;
    font-size: 24px;
    cursor: pointer;
}

.study-card__primary {
    height: 80px;
    font-size: 60px;
}

.study-card__secondary {
    width: 95%;
    font-size: 14px;
    margin-top: 30px;
    height: 40px;
    text-align: center;
    visibility: hidden;
}

.study-card__secondary--show {
    visibility: initial;
}