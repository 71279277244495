.footer {
    width: 100%;
    background: #0c66a6;
    color: #ffffff;
}

.footer__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 10px;
}

.footer span {
    margin: 4px 5px;
}