.home__header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10px;
}

.home__title {
    display: block;
    width: 300px;
    border-bottom: none;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    margin-top: 15px;
    letter-spacing: -1px;
}

.home__title span {
    color: #a70005;
    font-weight: 700;
}

.home__preview {
    width: 260px;
    aspect-ratio: 129 / 146;
    filter: drop-shadow(1px 5px 7px #858585);
}

.home__button-start {
    display: block;
    margin: 30px auto 0;
    min-width: 200px;
    padding: 12px 16px;
    border-radius: 3px;
    background-color: #92fd78;
    box-shadow: var(--box-shadow);
    font-size: 18px;
}

.home__button-loading {
    width: 50px;
    opacity: 0.6;
}

.home__text-small {
    display: block;
    text-align: center;
    margin-top: 4px;
    font-size: 12px;
    font-style: italic;
}

@media (hover: hover) {
    .home__button-start:hover {
        background-color: var(--color-green-hover);
    }
}

@media screen and (min-width: 768px) {
    .home__header {
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }

    .home__slogan {
        width: 55%;
        padding-right: 20px;
    }

    .home__title {
        width: 400px;
        font-size: 40px;
        margin-top: initial;
    }

    .home__preview-wraper {
        width: 45%;
    }

    .home__preview {
        width: 100%;
    }

    .home__button-start {
        margin-top: 50px;
    }
}

@media screen and (min-width: 820px) {
    .home__slogan {
        width: 50%;
        padding-right: 40px;
    }

    .home__preview-wraper {
        width: 50%;
    }

    .home__preview {
        width: 420px;
    }
}

.features {
    width: 100vw;
    margin-top: 30px;
    padding-top: 50px;
    background: linear-gradient(
        135deg, rgba(12,102,166,1) 0%, rgba(12,102,166,1) 6%, rgb(28 129 201) 100%);
    margin-left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
}

.features__wrapper {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features h2 {
    text-align: center;
}

.feature {
    max-width: 350px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature img {
    width: 70px;
}

.features h2 {
    padding: 0 10px;
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    text-shadow: 1px 1px #353535;
}

.feature h3 {
    text-align: center;
    margin-top: 15px;
    color: #ffd150;
    text-shadow: 1px 1px #525252;
}

.feature p {
    margin-top: 5px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .features__wrapper {
        max-width: 1000px;
        margin: 0 auto;
        padding: 40px 0;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .feature {
        width: 50%;
    }

    .features h2 {
        font-size: 30px;
        max-width: 720px;
        padding: initial;
    }
}

@media screen and (min-width: 1000px) {
    .feature {
        max-width: 320px;
    }

    .feature img {
        width: 60px;
    }

    .feature h3 {
        font-size: 22px;
    }

    .feature p {
        font-size: 15px;
        padding: 0 30px;
    }
}

.challenge {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    background: #f7f7f7;
}

.challenge__wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 50px 10px;
}

.challenge__title {
    text-align: center;
    font-size: 28px;
    color: #004d85;
    font-weight: 600;
}

.challenge__subsection {
    display: flex;
    flex-direction: column-reverse;
    margin: 20px auto 0;
    max-width: 420px;
}

.challenge__subsection--reverse {
    flex-direction: column-reverse;
}

.challenge__content {
    padding: 0 15px;
}

.challenge__content h3 {
    font-size: 22px;
    margin-bottom: 22px;
    margin-top: 25px;
    font-weight: 700;
    color: #004d85;
    line-height: 26px;
    text-align: center;
}

.challenge__content p {
    margin-bottom: 10px;
}

.challenge__image {
    width: 240px;
    margin: 50px auto 0;
}

@media screen and (min-width: 800px) {
    .challenge__title {
        font-size: 32px;
    }

    .challenge__subsection {
        flex-direction: row;
        margin: 120px auto 0;
        max-width: initial;
    }

    .challenge__subsection--reverse {
        flex-direction: row-reverse;
    }

    .challenge__content {
        max-width: 60%;
        padding: 0 45px;
    }

    .challenge__content h3 {
        font-size: 24px;
        margin-top: initial;
        text-align: initial;
    }

    .challenge__image {
        width: 300px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1000px) {
    .challenge__wrapper {
        width: 1000px;;
        padding: 80px 0;
    }
}

.methodology {
    margin: 50px 0;
}

.methodology__subsection {
    margin-top: 40px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.methodology__subsection--reverse {
    flex-direction: column;
}

.methodology__title {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    color: #8b0000;
}

.methodology__content {
    margin-top: 30px;
    padding: 0 10px;
    max-width: 460px;
}

.methodology h3 {
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    color: #8b0000;
}

.methodology p {
    margin-bottom: 10px;
}

.methodology__image {
    width: 128px;
}

@media screen and (min-width: 800px) {
    .methodology__subsection {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .methodology__subsection--reverse {
        flex-direction: row;
    }

    .methodology__title {
        font-size: 32px;
    }

    .methodology h3 {
        font-size: 24px;
        text-align: initial;
    }

    .methodology__image {
        margin-right: 40px;
    }

    .methodology__subsection--reverse .methodology__image {
        margin-right: 0;
        margin-left: 40px;
    }
}

.steps {
    width: 100vw;
    padding-top: 20px;
    background: #0c66a6;
    margin-left: 50%;
    transform: translateX(-50%);
}

.steps__wrapper {
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps__title {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    max-width: 420px;
    text-shadow: 1px 1px #353535;
}

.steps__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 40px;
}

.steps__card {
    position: relative;
    margin: 10px;
    border: 2px solid #000000;
    border-radius: 5px;
    background: #fdfdfd;
    max-width: 220px;
}

.steps__card-title {
    padding: 11px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.steps__card-title--one {
    background: linear-gradient(to bottom, #6cc2ff 98%, #fdfdfd 100%)
}

.steps__card-title--two {
    background: linear-gradient(to bottom, #ff5833d1 98%, #fdfdfd 100%)
}

.steps__card-title--three {
    background: linear-gradient(to bottom, #ffc04d 98%, #fdfdfd 100%)
}

.steps__card-title--four {
    background: linear-gradient(to bottom, #32c900ad 98%, #fdfdfd 100%)
}

.steps__card p {
    padding: 14px;
    min-height: 90px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 15px;
}

@media screen and (min-width: 600px) {
    .steps__title {
        font-size: 28px;
    }

    .steps__card {
        margin: 30px;
    }
}

@media screen and (min-width: 800px) {
    .steps__wrapper {
        max-width: 1000px;
        margin: 0 auto;
        padding: 30px 0;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .steps__title {
        font-size: 32px;
        max-width: initial;
    }

    .steps__card {
        margin: 30px 10px;
    }
}

@media screen and (min-width: 1000px) {
    .steps__cards {
        margin: 90px 0 50px;
    }

    .steps__arrow--top::after,
    .steps__arrow--bottom::after {
        content: '';
        position: absolute;
        right: -50%;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        z-index: -1;
    }

    .steps__arrow--top::after {
        border-top: 3px dashed #ebebeb;
        top: -40px;
    }

    .steps__arrow--bottom::after {
        border-bottom: 3px dashed #ebebeb;
        bottom: -40px;
    }
}