
.progressbar__svg {
    filter: drop-shadow(2px 2px 2px #e7e7e7);
}

.progressbar__background {
    fill: none;
    stroke-width: 0.78;
}

.progressbar__background--hanzi {
    stroke: var(--color-blue-light);
}

.progressbar__background--level {
    stroke: var(--color-green-light);
}

.progressbar__stroke {
    fill: none;
    stroke-dasharray: 0 20; /* Sólo hay que tocar el primer número, va de 0 a 19. */
    stroke-width: 0.8;
    transition: stroke-dasharray 600ms linear;
}

.progressbar__stroke--hanzi {
    stroke: var(--color-blue-dark);
}

.progressbar__stroke--level {
    stroke: var(--color-green-dark);
}