.reset-password__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 25px;
    margin: 0 auto;
}

.reset-password__form {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
    padding: 0 10px;
    width: 100%;
}

.reset-password__box--success {
    background-color: var(--color-green-light);
}

.reset-password__label {
    margin: 15px 0 5px 1px;
}

.reset-password__input {
    padding-left: 10px;
    font-size: 16px;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e2e2e2;
}

.reset-password__input:focus {
    border: 2px solid var(--color-blue);
}

.reset-password__button {
    font-size: 16px;
    padding: 15px 20px;
    border-radius: 3px; 
    margin-top: 20px;
    cursor: pointer;
    background-color: var(--color-green);
    box-shadow: var(--box-shadow);
}

.reset-password__button-loading {
    width: 50px;
    opacity: 0.6;
}

.reset-password__message {
    color: #b30000;
    font-weight: 600;
    margin-top: 15px;
    min-height: 20px;
    margin-left: 1px;
}

.modal__reset-password h2 {
    text-align: center;
}

.modal__reset-password-button {
    display: inline-block;
    font-size: 16px;
    min-width: 180px;
    padding: 15px 20px;
    border-radius: 3px; 
    margin-top: 30px;
    cursor: pointer;
    background-color: var(--color-green);
    box-shadow: var(--box-shadow);
}

@media (hover: hover) {
    .reset-password__button:hover,
    .modal__reset-password-button:hover {
        background-color: var(--color-green-hover);
    }
}

@media screen and (min-width: 600px) {
    .reset-password__container {
        width: 520px;
    }
}