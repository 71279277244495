.nav__logo {
    display: flex;
    align-items: center;
}

.nav__logo img {
    width: 30px;
}

.nav__logo p {
    font-size: 22px;
    margin-left: 3px;
}

.nav__logo span {
    font-weight: 600;
}