.nav__links {
    display: flex;
    align-items: center;
}

.nav__links li {
    list-style: none;
}

.nav__link {
    display: inline-block;
    list-style: none;
    padding: 8px 12px;
    margin-left: 5px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
}

.nav__link--register {
    display: none;
    background-color: var(--color-green);
}

.nav__link--register:active {
    background-color: var(--color-green-dark);
}

.nav__link--login {
    background-color: var(--color-blue);
}

.nav__link--login:active {
    background-color: var(--color-blue-dark);
}

.nav__profile {
    margin-top: 6px;
    position: relative;
}

.nav__profile-icon {
    position: relative;
    font-size: 34px;
    color: #636363;
    cursor: pointer;
    z-index: 101;
}

.nav__logged-links--background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.nav__logged-links {
    position: absolute;
    width: 220px;
    opacity: 0;
    margin-top: 5px;
    right: 5px;
    padding: 15px 0 10px;
    z-index: 100;
    background-color: #fcfcfc;
    border-radius: 3px;
    transform: translate(0, -10px);
    transition: opacity 200ms, transform 400ms;
    box-shadow: var(--box-shadow);
}

.nav__logged-links--open {
    opacity: 1;
    transform: translate(0, 0);
}

.nav__logged-links li {
    list-style: none;
    line-height: 60px;
}

.nav__link-button {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0 35px;
}

.nav__link-icon {
    font-size: 24px;
    margin-top: -4px;
}

.nav__link-icon--config {
    margin-left: 1px;
    margin-right: -2px;
}

.nav__link-icon--logout {
    color: #670000;
    margin-left: -1px;
}

.nav__link-text {
    margin-left: 10px;
}

@media screen and (min-width: 480px) {
    .nav__link--register {
        display: block;
    }
}

@media (hover: hover) {
    .nav__link--register:hover {
        background-color: var(--color-green-hover);
    }

    .nav__link--login:hover {
        background-color: var(--color-blue-hover);
    }
}