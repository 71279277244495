.legend {
    width: 40%;
    height: 10px;
    margin-top: 8px;
    background: linear-gradient(to right, #ff7147 0%, #f7b054 18%, #ffe380 30%, #c8ff95 50%, #88ea4e 100%);
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(2px 2px 2px #bebebe);
}

.legend__icon {
    font-size: 14px;
    filter: drop-shadow(1px 1px 1px #797979)
}

.legend__icon--good {
    margin-top: -34px;
    color: #88ea4e;
}

.legend__icon--bad {
    margin-top: -30px;
    color: #fe7949;
}