.study__container {
    position: relative;
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    padding-left: 40px;
    min-height: 400px;
}

.study__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 0;
}

.study__final-message {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    margin-top: 120px;
}

.study__final-message--back {
    font-size: 25px;
    margin-top: 20px;
}

.study__no-cards-message {
    width: 90%;
    margin-top: 110px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

@media screen and (min-width: 480px) {
    .study__no-cards-message {
        width: 480px;
    }
}
