.study-card__options {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    height: 135px;
}

.study-card__option {
    width: 45%;
    margin-top: 14px;
    padding: 18px;
    background-color: var(--color-blue-light);
    box-shadow: 2px 2px 4px #c5c5c5;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.study-card__option--correct {
    background-color: var(--color-green-dark);
    pointer-events: none;
}

.study-card__option--wrong {
    background-color: var(--color-red-dark);
    pointer-events: none;
}

@media (hover: hover) {
    .study-card__option:hover {
        background-color: #c6efff;
    }
}